<template>
  <div class="lang-list" v-if="!!langList">
    <a
        v-for="item in langList"
        v-bind:key="item.id"
        class="nocopy"
        v-bind:class="[item.icon ? 'icon fa-' + item.icon : '']"
        v-bind:href="item.href + page"
        v-bind:title="item.title"
    >
      {{ item.titleCode }}
    </a>
  </div>
</template>

<script>
export default {
  name: "langSelector",
  props: {
    langList: {
      required: true,
    },
    page: {},
  }
}
</script>

<style scoped>
.lang-list {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
}

.lang-list a {
  border: 3px solid rgb(23 23 23);
  border-top: 0;
  border-right: 0;
  border-radius: 0 5px 0 9px;
  padding: 2px 4px;
  cursor: pointer;
  font-weight: 400;
  color: #363636;
}
</style>