<template>
  <article class="panel">
    <img src="@/assets/images/me.jpg" alt="">
    <div class="info">
      <h1>{{bio.name}}</h1>
      <p>
        <b>{{bio.profession}}</b>. {{messages.WorkExperience}}: <b>{{bio.workExperience}}</b>.<br>
      </p>
      <p v-html="formattedDescription"></p>
      <LinksIcon
          v-bind:Items="bio.links"
      />
      <langSelector
          v-bind:langList="langList"
          v-bind:page="''"
      />
    </div>
  </article>
</template>

<script>
import LinksIcon from '@/components/LinksIcon.vue'
import langSelector from "@/components/langSelector.vue";
export default {
  name: 'HomePage',
  components: {
    langSelector,
    LinksIcon
  },
  computed: {
    formattedDescription() {
      return this.bio.description.replace(/\n/g, '<br>');
    }
  },
  props: {
    bio: {
      required: true,
    },
    messages: {
      required: true,
    },
    langList: {
      required: true,
    },
  }
}
</script>
<style scoped>
.info {
  margin: 5px 1em;
  flex-grow: 2;
  width: 300px;
}

img {
  flex-grow: 1;
  width: 200px;
  max-width: 200px;
  border-radius: 50%;
  background-color: #FFF;
  padding: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  margin: auto;
}
.panel {
  align-items: center;
}
@media screen and (min-width: 737px) {

}
@media screen and (max-width: 736px) {

}
</style>